@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


body {
  margin: 0;
  font-family: 'Noto Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #07255e;

}

::selection {
  background: #dd3928; /* WebKit/Blink Browsers */
  color: #fff;
}
::-moz-selection {
  background: #dd3928; /* Gecko Browsers */
  color: #fff;
}
