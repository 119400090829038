#footer.content-section {
    padding-top: 30vh;
}

.skill-box {
    min-width: 40%;
    border-left: solid 1px #0d2f75;
    padding-left: 20px;
}


.skill-box ul {
    opacity: 1;
}

.skill-item-container {
    padding: 0px;
}