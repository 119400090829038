.App {
  text-align: left;
  background-color: #02163a;
  background-image: linear-gradient(110deg, #02163a, rgba(0,0,0,0), #02163a);
  /*height: 100vh;*/
}

h1 {
  text-align: center;
  font-family: "Lobster", serif;
  font-size: 42px;
  margin-top: 0;
  font-weight: 400;
  margin-left: -20px;
  line-height: 50px;
  display: inline-flex;
}

h1:before {
  content: "<";
  color: #dd3928;
  font-size: 25px;
  font-family: 'Raleway', sans-serif;
  padding-right: 8px;
  font-weight: 400;
}

h1:after {
  content: " />";
  color: #dd3928;
  font-size: 25px;
  font-family: 'Raleway', sans-serif;
  padding-left: 10px;
  padding-top:3px;
  font-weight: 400;
}

h2 {

  font-size: 23px;
  margin-bottom: 0;
  line-height: 27px;
  font-weight: 700;
}

h3 {
  font-size: 15px;
  margin-bottom: 0;
  line-height: 20px;
  margin-top: 4px;
  font-weight: 400;
}

h4 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 30px;
}

h5 {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

h6 {
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}

p {
  font-size: 16px;
}

.App-header {
  display: block;
  flex-direction: row;

}


.App-header-left {
  min-width: 100%;
  position: relative;
  background: #f3efef;
  color: #000;
  padding-top: 30px;
  padding-bottom: 30px;
}

.App-header-right {
  width: 100%;
  color: #f3efef;
  min-height: 100vh;
  position: relative;
}


.container {
  position: relative;
  margin: 0 auto;
  width: 85%;
}

.container-two {
  width: 85%;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
}


@media screen and (min-width: 900px) {

  h1 {
    font-family: "Lobster", serif;
    font-size: 42px;
    margin-top: 0;
    font-weight: 400;
    margin-left: -24px;
    line-height: 50px;
    display: inline-flex;
  }

  h1:before {
    content: "<";
    color: #dd3928;
    font-size: 25px;
    font-family: 'Raleway', sans-serif;
    padding-right: 8px;
    font-weight: 400;
  }

  h1:after {
    content: " />";
    color: #dd3928;
    font-size: 25px;
    font-family: 'Raleway', sans-serif;
    padding-left: 10px;
    padding-top:3px;
    font-weight: 400;
  }

  h2 {
    font-size: 23px;
    margin-bottom: 0;
    line-height: 27px;
    font-weight: 700;
  }

  .App-header {
    display: flex;
    flex-direction: row;

  }
  .App-header-left {
    min-width: 320px;
    min-height: 100vh;
    position: fixed;
    background: #f3efef;
    color: #000;
    z-index: 10;
  }

  .slideIn {
    left: -320px;
    -webkit-animation: slide 0.8s forwards;
    -webkit-animation-delay: 0s;
    animation: slide 0.8s forwards;
    animation-delay: 0s;
  }

  @-webkit-keyframes slide {
    100% { left: 0; }
  }

  @keyframes slide {
    100% { left: 0; }
  }

  .App-header-right {
    width: -webkit-calc(100% - 320px);
    width:    -moz-calc(100% - 320px);
    width:         calc(100% - 320px);
    color: #f3efef;
    min-height: 100vh;
    position: absolute;
    right: 0;
    background-image: linear-gradient(110deg, #02163a, rgba(0,0,0,0), #02163a);

    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;

  }

  .container {
    position: absolute;
    margin: 0 auto;
    top: 50px;
    left: 50%;
    transform: translate(-50%);
    max-width: 500px;
    width: 80%;
  }



  .container-two {
    max-width: 800px;
    width: 80%;
    margin: 0 auto;
    position: relative;
    padding-top: 15vh;


  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

}

.card {
  padding: 20px 20px 15px 40px;
  /*border-radius: 7px;*/
  position: relative;
  transition: background-color 0.5s;
  border: solid 1px transparent;
}

.card:hover {
  transition: background-color 0.5s;
  opacity: 1;
  background-color: #162f60;
  border: solid 1px #223f79;
  border-radius: 7px;

}

ul {
  color: inherit;
  font-size: 14px;
  padding-left: 12px;
  opacity: 1;
}

@media screen and (min-width: 900px) {
  ul {
    opacity: 0.5;
    transition: opacity 0.5s;
  }

  .card:hover > ul {
    transition: opacity 0.5s;
    opacity: 1;
  }

  .card:hover > p {
    transition: opacity 0.5s;
    opacity: 1;
  }
}

li {
  padding-bottom: 7px;
  list-style: none;
}

li:last-child {
  padding-bottom: 0px;
}

li:before {
  content:"·";
  font-size: 20px;
  vertical-align:middle;
  line-height: 5px;
  margin-left: -10px;
  margin-right: 5px;
}

.link-title {
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  color: inherit;
  text-decoration: none;
  padding-bottom: 10px;
  font-size: 17px;
  line-height: 35px;
  transition: 0.3s;
}

.link-title:before {
  content: "— ";
  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
}

.back-home {
  display: inline-block;
}
.back-home:before {

  content: "|< ";
  margin-right: 10px;
}


#link.link-title:hover {
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.link-title:hover > .link-title:hover {
  color: #dd3928;
}

.content-section {
  position: relative;
  padding-bottom: 15vh;
}

/*#about.content-section {*/
/*  width: 600px;*/
/*}*/

.grid-container {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 20px;
}

.sb12:before {
  display: none;
}


.desktop-contact {
  display: none;
}
.mobile-contact {
  display: block;
}

@media screen and (min-width: 900px) {
  .desktop-contact {
    display: block;
  }

  .mobile-contact {
    display: none;
  }

  .sb12 {
    z-index: 9;
  }
  .sb12:before {
    display: inline-block;
    content: "";
    width: 0px;
    height: 0px;
    position: fixed;
    border-left: 20px solid #dd3928;
    border-right: 20px solid transparent;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    /*margin-top: 14vh;*/

    left: 320px;
    top: -50px;
    -webkit-animation: slide2 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slide2 0.7s forwards;
    animation-delay: 1.3s;
  }

  @-webkit-keyframes slide2 {
    100% { top: 14vh; }
  }

  @keyframes slide2 {
    100% { top:14vh; }
  }
}
