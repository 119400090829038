.skill-box {
    min-width: 40%;
    border-left: solid 1px #0d2f75;
    padding-left: 20px;
}

.skill-box li:before {
    content:"";
    font-size: 20px;
    vertical-align:middle;
    line-height: 5px;
    margin-left: -15px;
    margin-right: 5px;
}

.skill-box ul {
    opacity: 1;
}

.skill-item-container {
    padding: 0px;
}