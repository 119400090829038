#projectPage h2 {
    font-family: 'Raleway', sans-serif;
    margin-bottom: 20px;
    margin-top: 0px;
}

.iframe-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 7px;
    border: solid 2px #223f79;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

}




#projectPage h4 {
    margin-top: 0px;
    font-size: 22px;
}

#projectPage h5 {
    margin-top: 0px;
    font-size: 22px;
    padding-top: 0px;
    padding-bottom: 20px;
    font-family: 'Raleway';
}

.project-image-grid {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 1%;


}

.project-image-grid div {
    width: auto;
    max-width: 49%;
    display: block;
    border-radius: 7px;
    text-align: center;

}

#projectPage img {
    width: 100%;
    border-radius: 7px;
    border: solid 2px #1f499a;

}

#projectPage p {
   margin-bottom: 25px;
}

#projectPage h6 {
    /*display: inline-block;*/
    margin-top: 4px;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    border: solid 1px #0d2f75;
    border-top: 0px;
    padding-bottom: 5px;
    text-align: center;
    color: #6282c4;
}


.project-details {
    border-left: solid 1px #0d2f75;
    margin-bottom: 20px;
    margin-top: 10px;
    background: transparent;
    border-radius: 0px;
}

.project-details:hover {
    background: transparent;
    border-bottom: solid 1px transparent;
    border-right: solid 1px transparent;
    border-top: solid 1px transparent;
    border-radius: 0px;

}

.project-header-content {
    margin-bottom: 30px;
}
