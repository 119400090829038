#contact {
    position: relative;
    margin-top: 20px;
}

@media screen and (min-width: 900px) {

    #contact {
        position: fixed;
        bottom: 1%;
        margin-left: 20px;
        animation: float 5s ease-in-out infinite;
    }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}


.profile-image {
    border-radius: 50px;
    width: 65px;
    height: 65px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    border: solid 2px #fff;
    box-shadow: 1px 5px 10px #c6c6c6;
}

.contact-info {
    display: inline-block;
    vertical-align: middle;
}

.contact-info h3 {
    font-weight: 700;
    margin-bottom: 5px;
    margin-left: -1px;
}


.contact-info h6 {
    font-weight: 400;
    font-size: 13px;
}

.contact-info h6 span {
    color: green;
    font-size: 16px;
    margin-right: 4px;
}

.contact-info button {
    border: 0;
    padding: 0;
    cursor: pointer;
    background: transparent;
}

.contact-info .logo1 {
    display: inline-block;
    vertical-align: middle;
    width: 23px;
    margin-right: 11px;
    border-top: solid 2px transparent;
    border-bottom: solid 2px transparent;
}
.contact-info .logo1:hover {
    border-bottom: solid 2px #dd3928;
    padding-bottom: 2px;

}

.contact-info .logo2 {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    margin-right: 11px;
}

.content-container {
    padding-bottom: 20px;
}
.contact-me {
    color: #000;
    font-weight: bold;
    margin-top: 0px;
}

.tooltip .tooltiptext {
    text-align: left;
    visibility: hidden;
    width: 150px;
    color: #fff;
    padding: 5px 10px;
    border-radius: 6px;
    background: #02163a;
    position: absolute;
    z-index: 1;
    top: -13px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}