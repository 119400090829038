#companyCard {
    position: relative;
    margin-bottom: 20px;
}

#companyCard a {
    display: inline-block;
    vertical-align: middle;
    color: inherit;
    text-decoration: none;
    font-size: 16px;
}

#companyCard img {
    position: absolute;
    top: 20px;
    left: 0px;
    width: 45px;
    height: 45px;
    border-radius: 5px;
    z-index: 10;

}

#companyCard .card {
    margin-left: 25px;
}