.link-container {
    text-decoration: none;
    color: inherit;
    cursor: pointer;

}

#portfolioCard {
    border: solid 1px transparent;
    border-left: solid 1px #0d2f75;
    margin-bottom: 30px;
    margin-top: 20px;
    transition: background-color 0.5s;
    border-radius: 5px;
}

@media screen and (min-width: 900px) {
    #portfolioCard {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-bottom: 0px;

    }

    #portfolioCard:hover {
        transition: background-color 0.5s;
        opacity: 1;
        background-color: #162f60;
        border: solid 1px #223f79;
    }
}




#portfolioCard a {
    display: inline-block;
    vertical-align: middle;
    color: inherit;
    text-decoration: none;
    font-size: 16px;
}

#portfolioCard p {
    font-size: 14px;
}

#portfolioCard .project-image {
    border-radius: 3px;
    max-width: 90%;
    margin-left: 20px;
    height: 100%;

}

.portfolio-content1 {
    padding: 20px 0px 20px 30px;
}

@media screen and (min-width: 900px) {

    #portfolioCard .project-image {
        border-radius: 3px;
        max-width: 200px;
        margin-bottom: 0px;
        margin-left: 20px;

    }
}

.pill {
    padding: 5px 10px 7px;
    border-radius: 20px;
    background-color: #1f499a;
    margin-right: 10px;
    font-size: 12px;
    display: inline-block;
    margin-bottom: 10px;
}

#portfolioCard .about {
    transition: opacity 0.5s;
    opacity: 0.7;
}

#portfolioCard:hover > .about {
    transition: opacity 0.5s;
    opacity: 1;
}


