.skill-box {
    min-width: 40%;
}

.portfolio-card {
    display: flex;
    justify-content: space-between;
    border: solid 1px #444;
    border-radius: 7px;
    position: relative;
    color: #fff;
}

.thumbnail {
    flex: 1;
    width: 45%;
    margin: 1%;
    position: relative;
}

.overlayInfo {
    background: rgba(45,45,45, 0.8);
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 7px;
    text-align: center;
    transition: 0.3s;

}

.thumbnail:hover > .overlayInfo {
    display: block;
    cursor: pointer;
    transition: 0.3s;
}


#portfolio .portfolio-content {
    padding: 15px 20px;
}

.image-box {
    /*background: #fff;*/
    /*border-radius: 0px 7px 7px 0px;*/
    display: block;
}
/*#portfolio img {*/
/*    !*margin-top: 10px;*!*/
/*    width: 100%;*/
/*    border-radius: 7px;*/
/*    display: inline-block;*/
/*}*/

.grid-container_new {
    display: flex;
    justify-content: stretch;
    border-radius: 5px;
    padding: 20px;
}
